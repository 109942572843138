import { gql } from "@apollo/client"

export const UPLOAD_INVOICE = gql`
mutation UploadInvoice($data:uploadInvoicesInput!) {
  uploadInvoice(data:$data)  {
    debt
    process
    processDate
    processNumber
    vergiNumarasi
  }
}
`